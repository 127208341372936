import React from 'react'
import styled from 'styled-components'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'

const TextFieldDiv = styled.div`
    padding: 20px 0px;
`

const ReduxPrimaryInput = (props: any) => {
    const {
        input,
        label,
        errResp,
        type,
        InputProps,
        iconStart,
        handleClickShowPassword,
        showPassword,
        iconEnd,
        meta: { active, visited, touched, error }, ...custom } = props

    return (
        <TextFieldDiv>
            <TextField
                id="input-with-icon-textfield"
                label={(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : label}
                variant="outlined"
                error={(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : null}
                fullWidth
                type={type}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {iconStart}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        iconEnd && <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {iconEnd}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                {...input}
                {...custom}
            />
        </TextFieldDiv>
    )
}

export default ReduxPrimaryInput
