import { FunctionComponent } from 'react';
import LogoImages from '../../assets/images/sizey_logo_white.png'
import styled from 'styled-components'
import Moon from '../../assets/images/moon.svg'
import Sun from '../../assets/images/sun.svg'
import { COLORS } from '../../helper/colors';

const { BLUE, WHITE } = COLORS;

const Image: any = styled.img`
  height: ${(props: any) => props.height ? `${props.height}px` : '25px'};
  cursor: pointer;
  z-index: 99;
  width: ${(props: any) => props.width ? `${props.width}px` : '95px'};
`

const NavBarIcons = styled.div`
  display: flex;
  padding: 26px 30px;
  justify-content: space-between;
  background-color: ${BLUE};
  @media(max-width: 768px) {
    padding: 20px 30px;
  }
  @media(max-width: 600px) {
    padding: 20px 15px;
  }
`
const ThemeChange = styled.div`
  background: ${WHITE};
  border-radius: 20px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
`;

const Navbar: FunctionComponent<any> = ({ theme, setMode }) => {

  const handleDarkTheme = () => {
    if (theme.name === 'Light') {
      setMode('dark')
    } else {
      setMode('light')
    }
  }

  return (
    <div>
      <NavBarIcons>
        <Image src={LogoImages} alt='logo-image' />
        <ThemeChange>
          <Image width={18} height={18} src={theme.name === 'Light' ? Moon : Sun} alt='theme' onClick={handleDarkTheme} />
        </ThemeChange>
      </NavBarIcons>
    </div>

  )
}

export default Navbar;