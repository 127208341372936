import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.color};
    font-family: 'Roboto', sans-serif;
    transition: all 0.50s linear;
    .heading {
        color: ${({ theme }) => theme.colors.text};
    }
    .link-text {
        color: ${({ theme }) => theme.colors.color};
    }
    .MuiButton-containedPrimary {
        background-color: #003777;
        font-weight: 700;
        @media (max-width: 600px){
            font-size: 13px;
        }
    }
    .MuiInputBase-input {
        color: ${({ theme }) => theme.colors.color};
    }
    .MuiInput-underline:before {
        border-bottom: 1px solid ${({ theme }) => theme.colors.color};
    }
    .MuiNativeSelect-icon {
        color: ${({ theme }) => theme.colors.color};
    }
    
     .MuiInputLabel-outlined {
        color: ${({ theme }) => theme.colors.borderColor}
     }
    .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.borderColor}
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.color}
    }
    .MuiButton-contained.Mui-disabled {
        background-color: ${({ theme }) => theme.colors.disableButtonBg};
        color: ${({ theme }) => theme.colors.disableButtonColor};
    }
    .MuiSvgIcon-root {
        color: ${({ theme }) => theme.colors.text};
    }
  }
`;