import { BrowserRouter } from 'react-router-dom';
import FirebaseProvider from './providers/FirebaseProvider';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <BrowserRouter>
      <FirebaseProvider>
          <AppRoutes />
      </FirebaseProvider>
    </BrowserRouter>
  );
}

export default App;
