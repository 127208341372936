import { FunctionComponent, ChangeEvent, useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Field, reduxForm, formValueSelector } from 'redux-form'


import ReduxPrimaryInput from '../atoms/ReduxInputField'
import { FirebaseContext } from '../../providers/FirebaseProvider'
import { emailValidation, requiredField, validatePasswordMatch } from '../../helper/validations'
import { DialogActions } from '@material-ui/core'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { COLORS } from '../../helper/colors'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useAuthRedirect } from '../../hooks/AuthRedirect'

const fNameRequire = requiredField('Full Name')
const emailRequire = requiredField('Email')
const passwordRequire = requiredField('Password')
const cnPasswordRequire = requiredField('Confirm Password')

const { RED, WHITE, BLUE, BLACK } = COLORS;

const SignUpContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
    @media (max-width: 600px) {
        height: 100%;
        margin: 40px 0px;
    }
    .main {
        a {
            color: ${({ theme }) => theme.colors.color};
            padding-left: 5px;
        }
    }
    .main {
        display: flex;
        position: relative;
        margin-bottom: 15px;
        margin-top: 20px;
        cursor: pointer;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.color};
        @media (max-width: 600px){
            font-size: 14px;
        }
    }
    input[type=checkbox] {
        visibility: hidden;
        width: 20px;
        @media (max-width: 600px){
            width: 16px;
        }
    }
    .geekmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
        border: 2px solid ${({ theme }) => theme.colors.color};
        border-radius: 3px;
    }
    .main input:checked ~ .geekmark {
        background-color: ${BLUE};
    }
    .geekmark:after {
        content: '';
        position: absolute;
        display: none;
    }
    .main input:checked ~ .geekmark:after {
        display: block;
    }
    .main .geekmark:after {
        left: 4px;
        bottom: 3px;
        width: 4px;
        height: 10px;
        color: black;
        border: solid white;
        transform: rotate(45deg);
        border-width: 0px 2px 2px 0;
    }
`
const Container = styled.div`
    width: 30%;
    background-color: ${({ theme }) => theme.colors.card};
    padding: 30px;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: 60%;
    }
    @media (max-width: 600px) {
        width: 80%;
    }
`;

const ErrorMsg = styled.h2`
    color: ${RED};
    font-size: 18px;
    font-family: sans-serif;
`;

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '80%',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    submit: {
        width: '100%',
        color: WHITE,
        fontWeight: 'bold',
        margin: '20px 0px',
        height: 40,
    },
    textField: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    title: {
        color: BLUE,
        fontWeight: 'bold',
        paddingBottom: 10,
        fontSize: 24,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    alreadyAccount: {
        marginTop: 10,
        paddingLeft: 5,
        color: BLACK,
    },
    alreadyAccountText: {
        marginTop: 10,
        color: BLACK,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 50
        }
    }
}))
interface RegisterForm {
    email: string,
    password: string,
    acceptTerms: boolean,
    name: string,
}

const RegisterView: FunctionComponent = (props: any) => {
    const [termsAndCondition, setTermsAndCondition] = useState(false)
    const location = useLocation();

    const classes = useStyles()
    const authContext: any = useContext(FirebaseContext)
    const { emailValue, passwordValue, confirmPasswordValue, nameValue, invalid, handleSubmit } = props
    const [registerForm, setRegisterForm] = useState<RegisterForm>({ name: '', email: '', password: '', acceptTerms: false });
    const [openPopup, setOpenPopup] = useState<Boolean>(false);
    const [showPassword, setShowPassword] = useState<Boolean>(false);
    const [showConfirmPassword, setConfirmPassword] = useState<Boolean>(false);

    useAuthRedirect(true);

    const updateRegisterForm = (event: ChangeEvent<HTMLInputElement>) => {
        setRegisterForm(current => ({ ...current, [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value }));
    }

    const register = async (e: any) => {
        try {
            removeError();
            await authContext.auth?.createUserWithEmailAndPassword(registerForm.email, registerForm.password);
            const user = await firebase.auth().currentUser;
            user?.updateProfile({ displayName: registerForm.name });
            await firebase.auth().currentUser?.sendEmailVerification();
            setOpenPopup(true);
        } catch (e: any) {
            //show some error when we start using some UI lib
            authContext.setLoginError(e.message);
        }
    }

    const removeError = () => {
        authContext.loginError && authContext.setLoginError("");
    }

    const handleLogin = async () => {
        await authContext.auth?.signInWithEmailAndPassword(registerForm.email, registerForm.password);
    }

    useEffect(() => {
        return () => removeError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.loginError]);

    const redirectLink = window.location.search.includes('my') ? 'https://www.sizey.ai/privacy-policy' : 'https://www.sizey.ai/terms-of-service';

    return (
        <SignUpContainer>
            <Dialog onClose={handleLogin} open={Boolean(openPopup)}>
                <Typography style={{ padding: '20px' }} variant="subtitle1" component="div">
                    Please check your email, to verify your account.
                </Typography>
                <DialogActions>
                    <Button onClick={handleLogin} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Container>
                <div className={classes.paper}>
                    <Typography component='h1' variant='h4' className={`${classes.title} heading`}> Register</Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(register)}>
                        <Field
                            type='text'
                            name='name'
                            placeholder='Name'
                            validate={[fNameRequire]}
                            component={ReduxPrimaryInput}
                            className='input-text'
                            onFocus={removeError}
                            onChange={updateRegisterForm}
                            iconStart={<PersonOutlineOutlinedIcon />}
                        />
                        <Field
                            type='email'
                            name='email'
                            placeholder='Email'
                            component={ReduxPrimaryInput}
                            validate={[emailRequire, emailValidation]}
                            className='input-text'
                            onChange={updateRegisterForm}
                            onFocus={removeError}
                            iconStart={<MailOutlineOutlinedIcon />}
                        />
                        <Field
                            type={showPassword ? 'text' : 'password'}
                            name='password'
                            placeholder='Password'
                            validate={[passwordRequire]}
                            component={ReduxPrimaryInput}
                            onChange={updateRegisterForm}
                            className='input-text'
                            onFocus={removeError}
                            iconStart={<LockOutlinedIcon />}
                            handleClickShowPassword={() => setShowPassword(!showPassword)}
                            iconEnd={showPassword ? <Visibility /> : <VisibilityOff />}
                        />
                        <Field
                            type={showConfirmPassword ? 'text' : 'password'}
                            name='confirm_password'
                            placeholder='Re-type password'
                            component={ReduxPrimaryInput}
                            validate={[cnPasswordRequire, validatePasswordMatch]}
                            className='input-text'
                            onFocus={removeError}
                            iconStart={<LockOutlinedIcon />}
                            handleClickShowPassword={() => setConfirmPassword(!showConfirmPassword)}
                            iconEnd={showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        />
                        {authContext.loginError && <ErrorMsg>{authContext.loginError}</ErrorMsg>}
                        <label className='main'>
                            <input type='checkbox' onClick={() => setTermsAndCondition(!termsAndCondition)} />
                            I agree to the <a href={redirectLink}>Terms of Service</a>
                            <span className='geekmark'></span>
                        </label>
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            className={classes.submit}
                            disabled={!(!!emailValue && !!passwordValue && !!confirmPasswordValue && !!nameValue && !invalid && termsAndCondition)}
                        >
                            Register
                        </Button>
                        <Grid container justifyContent='center'>
                            <Grid item className={classes.alreadyAccountText}>
                                <Link to={{ pathname: '/login', search: location.search }} className={`${classes.alreadyAccount} link-text`}>
                                    Already have an account? Log in
                                </Link>
                            </Grid>
                        </Grid >
                    </form >
                </div >
            </Container >
        </SignUpContainer >
    )
}

// @ts-ignore
const Register = reduxForm({ form: 'signup_form' })(RegisterView)
const selector = formValueSelector('signup_form')

const mapStateToProps = (state: any) => ({
    nameValue: selector(state, 'name'),
    emailValue: selector(state, 'email'),
    passwordValue: selector(state, 'password'),
    confirmPasswordValue: selector(state, 'confirm_password')
})


export default connect(mapStateToProps, null)(Register)