import MoonDark from '../assets/images/moon.svg'
import SunDark from '../assets/images/sun.svg'
import MoonLight from '../assets/images/white_moon.svg'
import SunLight from '../assets/images/white_sun.svg'

export const THEME = {
    "light": {
        "name": "Light",
        "colors": {
            "body": "#FFFFFF",
            "text": "#003777",
            "color": "#000000",
            "card": "#FFFFFF",
            "borderColor": 'rgba(0, 0, 0, 0.23)',
        },
        "moonIcon": MoonDark,
        "sunIcon": SunDark,
    },
    "dark": {
        "name": "Dark",
        "colors": {
            "body": "#121212",
            "text": "#FFFFFF",
            "color": "#FFFFFF",
            "card": "#212121",
            "borderColor": '#9d9898',
        },
        "moonIcon": MoonLight,
        "sunIcon": SunLight,
    }
}