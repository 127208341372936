import { FunctionComponent, ChangeEvent, useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Field, reduxForm, formValueSelector } from 'redux-form'


import ReduxPrimaryInput from './atoms/ReduxInputField'
import { FirebaseContext } from '../providers/FirebaseProvider'
import { emailValidation, requiredField } from '../helper/validations'
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import { COLORS } from '../helper/colors'

const emailRequire = requiredField('Email')

const { RED, BLUE, BLACK } = COLORS;

const SignUpContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
    @media (max-width: 600px) {
        background: unset;
    }
`
const Container = styled.div`
    width: 30%;
    background-color: ${({theme}) => theme.colors.card};
    padding: 30px;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: 60%;
    }
    @media (max-width: 600px) {
        width: 80%;
    }
`;

const ErrorMsg = styled.h2`
    color: ${RED};
    font-size: 18px;
    font-family: sans-serif;
`;

const SuccessMsg = styled.h2`
    color: #00FF00;
    font-size: 18px;
    font-family: sans-serif;
`;

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '80%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    submit: {
        width: '100%',
        fontWeight: 'bold',
        margin: '20px 0px',
        height: 50
    },
    textField: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    title: {
        color: BLUE,
        fontWeight: 'bold',
        paddingBottom: 23,
        fontSize: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        }
    },
    alreadyAccount: {
        marginTop: 10,
        paddingLeft: 5,
        color: BLACK,
    },
    alreadyAccountText: {
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    }
}))

interface forgotForm {
    email: string,
    new_password: string
}

const ForgotPasswordView: FunctionComponent = (props: any) => {
    const classes = useStyles()
    const location = useLocation();
    const authContext: any = useContext(FirebaseContext)
    const { emailValue, handleSubmit, invalid } = props
    const [forgotForm, setforgotForm] = useState<forgotForm>({ email: '', new_password: '' });
    const [successMsg, setSuccessMsg] = useState('');

    const handleForgotForm = (event: ChangeEvent<HTMLInputElement>) => {
        setforgotForm(current => ({ ...current, [event.target.name]: event.target.value }));
    }

    const forgotPassword = async (e: any) => {
        authContext.auth?.sendPasswordResetEmail(forgotForm.email)
            .then(function () {
                setSuccessMsg("Email sent successfully")
            })
            .catch(function (e: any) {
                authContext.setLoginError(e.message)

            })
    }

    const removeError = () => {
        authContext.loginError && authContext.setLoginError("");
    }

    useEffect(() => {
        return () => removeError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.loginError]);

    return (
        <SignUpContainer>
            <Container>
                <div className={classes.paper}>
                    <Typography component='h1' variant='h4' className={`${classes.title} heading`}>Forgot Password</Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(forgotPassword)}>
                        <Field
                            type='email'
                            name='email'
                            placeholder='Email'
                            component={ReduxPrimaryInput}
                            validate={[emailRequire, emailValidation]}
                            onChange={handleForgotForm}
                            onFocus={removeError}
                            iconStart={<MailOutlineOutlinedIcon/>}
                        />
                        {authContext.loginError && <ErrorMsg>{authContext.loginError}</ErrorMsg>}
                        {successMsg && <SuccessMsg>{successMsg}</SuccessMsg>}
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            className={classes.submit}
                            disabled={!(emailValue && !invalid)}
                        >
                            Get Reset link
                        </Button>
                        <Grid container justifyContent='center'>
                            <Grid item className={classes.alreadyAccountText}>
                                <Link to={{ pathname: '/login', search: location.search }} className={`${classes.alreadyAccount} link-text`}>
                                    Log In
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item className={classes.alreadyAccountText}>
                                <Link to={{ pathname: '/register', search: location.search }} className={`${classes.alreadyAccount} link-text`}>
                                    Create a new account ? Register
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </SignUpContainer>
    )
}

const FORM_NAME = 'Forgot_form';
// @ts-ignore
const ForgotPassword = reduxForm({ form: FORM_NAME })(ForgotPasswordView);
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state: any) => ({
    emailValue: selector(state, 'email')
});


export default connect(mapStateToProps, null)(ForgotPassword)