import { FunctionComponent } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import LoginView from './views/login/Login';
import LogoutView from './views/logout/Logout';
import RegisterView from './views/register/Register';
import ResetPasswordView from './views/ForgotPassword';
import Navbar from './views/navbar/Navabar'
import { ThemeProvider } from 'styled-components';
import { useTheme } from './theme/useTheme'
import { GlobalStyles } from './globalStyle'

const routes = [
    {
        path: '/login',
        component: LoginView
    },
    {
        path: '/logout',
        component: LogoutView
    },
    {
        path: '/register',
        component: RegisterView
    },
    {
        path: '/forgotpassword',
        component: ResetPasswordView
    },
]

const AppRoutes: FunctionComponent = () => {
    
    const location = useLocation()
    const { theme, setMode } = useTheme();

    return (
        <div>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <Navbar theme={theme} setMode={setMode} />
                <Switch>
                    {routes.map(({ path, component }) => <Route key={path} exact path={path} component={component} />)}
                    <Redirect from='/' to={{ pathname: '/login', search: location.search }} />
                </Switch>
            </ThemeProvider>
        </div>
    )
}

export default AppRoutes;
