import { FunctionComponent, useContext, useEffect } from 'react';
import { FirebaseContext } from '../../providers/FirebaseProvider'
import { useLocation } from 'react-router-dom'

const LogoutView: FunctionComponent = () => {
    const authContext = useContext(FirebaseContext);
    const location = useLocation();
    useEffect(() => {
        (async () => {
            if(authContext.auth){
                await authContext.auth.signOut();
                const search: any = new URLSearchParams(location.search)
                if (search.get('path')) {
                    window.location.href = decodeURIComponent(search.get('path'));
                } else if (search.get('redirectApp')) {
                    window.location.href = process.env.REACT_APP_LOGIN_URL + location.search;
                }
            }
        })();
    }, [authContext.auth, location.search])

    return(
       <span></span>
    )
}

export default LogoutView;