
import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { FirebaseContext } from "../providers/FirebaseProvider";

export const  useAuthRedirect = (shouldBeRedirect: Boolean) => {
    const authContext: any = useContext(FirebaseContext);
    const location = useLocation();
    const newSearch: any = new URLSearchParams(window.location.search)
    let query1 = ''
    if (newSearch) {
        for (const param of newSearch) {
            if (param[0] !== 'redirectApp') {
                query1 = query1 + '&' + param[0] + '=' + param[1]
            }
        }
    }

    useEffect(() => {
        (async () => {
            // const emailVerified = authContext.user?.emailVerified;
            // const emailVerified = true;
            if (authContext.user && shouldBeRedirect) {
                const token = await authContext.user.getIdToken(true);
                const query = new URLSearchParams(location.search);
                const redirectApp = query.get('redirectApp') || 'portal'
                if (redirectApp) {
                    const response = await fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}/token`, {
                        method: 'POST',
                        headers: { 'Content-type': 'application/json' },
                        body: JSON.stringify({
                            token: token.toString(),
                            redirectApp: redirectApp.toString()
                        }),
                    });

                    if (response.status === 200) {
                        const body = await response.json();
                        window.location.replace(`${body.redirectUrl}${query1}`);
                    }
                }
            }
        })();
        // eslint-disable-next-line
    }, [authContext.user, location.search, shouldBeRedirect]);
}