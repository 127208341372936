import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth'

interface FirebaseContextState {
    user?: firebase.User | null;
    auth?: firebase.auth.Auth;
    loginError?: string;
    setLoginError?: (error: string) => void;
}

export const FirebaseContext = createContext<FirebaseContextState>({});

const FirebaseProvider: React.FunctionComponent = ({ children }) => {

    const [firebaseContextState, setFirebaseContextState] = useState<FirebaseContextState>({})

    const setLoginError = (error: string) => {
        setFirebaseContextState(current => ({ ...current, loginError: error }));
    }

    useEffect(() => {
        let app: firebase.app.App;

        if (firebase.apps.length === 0) {
            app = firebase.initializeApp({
                apiKey: process.env.REACT_APP_FIREBASE_HOSTING_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_HOSTING_AUTH_DOMAIN,
                projectId: process.env.REACT_APP_FIREBASE_HOSTING_PROJECT_ID,
                appId: process.env.REACT_APP_FIREBASE_HOSTING_APP_ID
            });
        } else {
            app = firebase.apps[0];
        }

        setFirebaseContextState(currentState => ({ ...currentState, auth: app.auth() }))

        return app.auth().onAuthStateChanged((user) => {
            setFirebaseContextState(current => ({...current, user: user, setLoginError}))
        });
    }, []);

    return (
        <FirebaseContext.Provider value={firebaseContextState}>
            { children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseProvider;