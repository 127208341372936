import { FunctionComponent, ChangeEvent, useContext, useState, useEffect } from "react"
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { FirebaseContext } from "../../providers/FirebaseProvider";
import firebase from 'firebase';

import ReduxPrimaryInput from '../atoms/ReduxInputField';
import { useAuthRedirect } from "../../hooks/AuthRedirect";
import { emailValidation, requiredField } from '../../helper/validations'
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import GoogleIcon from '../../assets/images/google_icon.svg';
import { COLORS } from "../../helper/colors";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const { RED, LIGHT_GRAY, WHITE, BLACK } = COLORS;
interface LoginCredentials {
    email: string,
    password: string,
}

const emailRequire = requiredField('Email')
const passwordRequire = requiredField('Password')

const SignInContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
    @media (max-width: 600px) {
        height: 100%;
        margin: 40px 0px;
    }
`
const Container = styled.div`
    width: 30%;
    background-color: ${({ theme }) => theme.colors.card};
    padding: 30px;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: 60%;
    }
    @media (max-width: 600px) {
        width: 80%;
    }
`;

const ErrorMsg = styled.h2`
    color: ${RED};
    font-size: 18px;
    font-family: sans-serif;
`;

const ForgotContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 30px 0px 60px 0px;
`;

const MultipleMethod = styled.div`
    display: flex;
    align-items: center;
`;

const HR = styled.hr`
    width: 45%;
    background-color: ${LIGHT_GRAY};
    height: 1px;
`;

const Text = styled.h2`
    font-size: 18px;
    color: ${LIGHT_GRAY};
    font-weight: 400;
    margin: 10px 0px;
    width: 50px;
`;

const Img = styled.img`
    margin-left: 2em;
`;

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    form: {
        width: '80%',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: 33,
            marginBottom: 23
        }
    },
    submit: {
        width: "100%",
        color: WHITE,
        fontWeight: 'bold',
        height: 50,
        margin: '20px 0px',
    },
    textField: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    title: {
        paddingBottom: 20,
        fontWeight: 'bold',
        fontSize: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        }
    },
    google: {
        background: '#DEEEFF !important',
        width: '100%',
        color: BLACK,
        height: 45,
        marginTop: 20
    },
    alreadyAccount: {
        color: BLACK,
        paddingLeft: 5,
    }
}))

const LoginView: FunctionComponent = (props: any) => {
    const { emailValue, passwordValue, invalid, handleSubmit } = props
    const classes = useStyles()
    const location = useLocation();
    const authContext: any = useContext(FirebaseContext);
    const [credentials, setCredentials] = useState<LoginCredentials>({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState<Boolean>(false);
    const [shouldBeRedirect, setShouldBeRedirect] = useState<Boolean>(false);

    useAuthRedirect(shouldBeRedirect);

    const loginWithGoogle = async () => {
        removeError();
        if (authContext.auth) {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.setCustomParameters({ 'prompt': 'select_account' });
            await authContext.auth.signInWithPopup(provider);
            await checkEmailIsVerifiedOrNot();
        }
    }

    const updateCredentials = (event: ChangeEvent<HTMLInputElement>) => {
        setCredentials(current => ({ ...current, [event.target.name]: event.target.value }));
    }

    const checkEmailIsVerifiedOrNot = async () => {
        const emailVerified = firebase.auth().currentUser?.emailVerified;
        if (!emailVerified) {
            await authContext.auth.signOut();
            return authContext.setLoginError("Email not verified. Please verify and try again.");
        }
        setShouldBeRedirect(true);
    }

    const loginWithEmail = async (e: any) => {
        removeError();
        try {
            await authContext.auth?.signInWithEmailAndPassword(credentials.email, credentials.password);
            await checkEmailIsVerifiedOrNot();
        } catch (e: any) {
            authContext.setLoginError('Invalid credentials. Please review and try again.')
        }
    }

    useEffect(() => {
        return () => {
            removeError();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.loginError]);

    const removeError = () => {
        authContext.loginError && authContext.setLoginError("");
    }

    return (
        <SignInContainer>
            <Container>
                <div className={classes.paper}>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(loginWithEmail)}>
                        <Typography component='h1' variant='h4' className={`${classes.title} heading`}>Sign In</Typography>
                        <Field
                            type='email'
                            name='email'
                            placeholder='Email'
                            component={ReduxPrimaryInput}
                            validate={[emailRequire, emailValidation]}
                            onChange={updateCredentials}
                            onFocus={removeError}
                            iconStart={<MailOutlineOutlinedIcon />}
                        />
                        <Field
                            type={showPassword ? 'text' : 'password'}
                            name='password'
                            placeholder='Password'
                            validate={[passwordRequire]}
                            component={ReduxPrimaryInput}
                            onChange={updateCredentials}
                            onFocus={removeError}
                            iconStart={<LockOutlinedIcon />}
                            handleClickShowPassword={() => setShowPassword(!showPassword)}
                            iconEnd={showPassword ? <Visibility /> : <VisibilityOff />}
                        />
                        {authContext.loginError && <ErrorMsg>{authContext.loginError}</ErrorMsg>}
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={`${classes.submit} button-primary`}
                            disabled={!(!!emailValue && !!passwordValue && !invalid)}
                        >
                            Sign In
                        </Button>
                        <ForgotContainer>
                            <Grid item>
                                <Link to={{ pathname: '/forgotpassword', search: location.search }} className={`${classes.alreadyAccount} link-text`}>
                                    Forgot Password
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to={{ pathname: '/register', search: location.search }} className={`${classes.alreadyAccount} link-text`}>
                                    New at Sizey! Sign up
                                </Link>
                            </Grid>
                        </ForgotContainer>
                        <MultipleMethod>
                            <HR />
                            <Text>Or</Text>
                            <HR />
                        </MultipleMethod>
                        <Grid >
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.google}
                                onClick={loginWithGoogle}
                            >Sign in with Google
                                <Img src={GoogleIcon} />
                            </Button>
                        </Grid>
                    </form>
                </div>
            </Container>
        </SignInContainer >
    )
}

const Login = reduxForm({ form: 'login_form' })(LoginView)
const selector = formValueSelector('login_form')

const mapStateToProps = (state: any) => ({
    emailValue: selector(state, 'email'),
    passwordValue: selector(state, 'password'),
})


export default connect(mapStateToProps, null)(Login)
